import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

enum CloudHomeFeatureToggle {
  newCloudHomeRedesign = 'newCloudHomeRedesign',
  cloudHomeRedesignWithCanvasAndBackground = 'cloudHomeRedesignWithCanvasAndBackground',
  integrationPreviews = 'cloudHomeConnectionPreviews',
  homeSetupGuide = 'homeSetupGuide',
}

type CloudHomeFeatureToggles = FeatureToggles & {
  [key in CloudHomeFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: CloudHomeFeatureToggle): boolean {
  return (config.featureToggles as CloudHomeFeatureToggles)[featureName] ?? false;
}

export function isNewCloudHomeRedesignEnabled(): boolean {
  return isFeatureToggleEnabled(CloudHomeFeatureToggle.newCloudHomeRedesign);
}

export function isCloudHomeRedesignWithCanvasAndBackgroundEnabled(): boolean {
  return isFeatureToggleEnabled(CloudHomeFeatureToggle.cloudHomeRedesignWithCanvasAndBackground);
}

export function isCloudRBACRolesEnabled(): boolean {
  return config.featureToggles.cloudRBACRoles ?? false;
}

export function isConnectionsPreviewEnabled(): boolean {
  return isFeatureToggleEnabled(CloudHomeFeatureToggle.integrationPreviews);
}

export function isHomeSetupGuideEnabled(): boolean {
  return isFeatureToggleEnabled(CloudHomeFeatureToggle.homeSetupGuide);
}
